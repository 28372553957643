import DateOnly from '../utils/dateOnly'
import { Prettify } from '../utils/utils'
import { ApiClient, BlobResponse } from "./ApiClient"
import { IdDisplayPair, IdResult, StronglyTypedId } from './Common'
import { getResource, postResource, putResource } from "./Search"
import { UserId } from './User'
import { AnsweredDigitalDocumentRevisionStatusId } from './WellKnowIds'

export enum InputTypeId {
    None = 0,
    Table = 1,
    Tuple = 2,
    Vector = 3,
    PlainText = 4,
    YesNo = 5,
    Compliance = 6,
    RichText = 7,
    Checklist = 8,
    CalendarDate = 9,
    Decimal = 10,
    Month = 11,
    Year = 12,
    SingleEntity = 13,
    Integer = 14,
    MultiEntity = 15,
    CalendarDateRange = 16,
}

export enum TemplateItemTypeId {
    Label = 0,
    Table = 1,
    Tuple = 2,
    Vector = 3,
    PlainText = 4,
    YesNo = 5,
    Compliance = 6,
    RichText = 7,
    Checklist = 8,
    CalendarDate = 9,
}

export type AnsweredDigitalDocument =
    {
        id: AnsweredDigitalDocumentId
        templateId: number
        header?: AnsweredSheet
        body: AnsweredSheet[]
        footer?: AnsweredSheet
        isLocked: boolean
        mode: DigitalDocumentMode
        isDraftingAllowed: boolean
        isPublishingAllowed: boolean
        currentRevision: AnsweredDigitalDocumentRevision
    }

export enum DigitalDocumentMode {
    Read = 0,
    Edit = 1,
}

export type AnsweredSheet =
    {
        id: AnsweredSheetId
        location: LayoutLocation
        sheetNumber: number
        answers: AnsweredDigitalDocumentItem[]
    }

export type AnsweredDigitalDocumentId = StronglyTypedId<'AnsweredDigitalDocumentId'>
export type TemplateItemId = StronglyTypedId<'TemplateItemId'>
export type AnsweredSheetId = StronglyTypedId<'AnsweredSheetId'>
export type AnsweredDigitalDocumentItemId = StronglyTypedId<'AnsweredDigitalDocumentItemId'>
export type DigitalDocumentTemplateId = StronglyTypedId<'DigitalDocumentTemplateId'>

export type AnsweredDigitalDocumentItem<TAnswer extends Answer = Answer> =
    {
        id?: AnsweredDigitalDocumentItemId
        templateItemId: TemplateItemId
        answer?: TAnswer
        secondaryAnswer?: Answer
        elaborationText?: RichTextContent
        isNotApplicable?: boolean
        isLocked?: boolean
    }

export type Answer =
    | undefined
    | NoneAnswer
    | PlainTextAnswer
    | RichTextAnswer
    | YesNoAnswer
    | ComplianceAnswer
    | DecimalAnswer
    | TableAnswer
    | EntityAnswer
    | YearAnswer
    | MonthAnswer
    | ChecklistAnswer
    | TupleAnswer
    | CalendarDateAnswer
    | IntegerAnswer
    | MultiEntityAnswer
    | VectorAnswer
    | CalendarDateRangeAnswer

type AnswerBase<T extends InputTypeId> = {
    inputTypeId: T
}

export type NoneAnswer = AnswerBase<InputTypeId.None>
export type TableAnswer = AnswerBase<InputTypeId.Table> & {
    value: Answer[][],
    numbering: string[] | undefined
    automaticSummation: Answer[] | undefined
}
export type ChecklistAnswer = AnswerBase<InputTypeId.Checklist> & { value: boolean[] }
export type TupleAnswer = AnswerBase<InputTypeId.Tuple> & { value: Answer[] }
export type VectorAnswer = AnswerBase<InputTypeId.Vector> & { value: Answer[] }
export type PlainTextAnswer = AnswerBase<InputTypeId.PlainText> & { value: string }
export type RichTextAnswer = AnswerBase<InputTypeId.RichText> & { value: RichTextContent }
export type CalendarDateAnswer = AnswerBase<InputTypeId.CalendarDate> & { value: DateOnly }
export type CalendarDateRangeAnswer = AnswerBase<InputTypeId.CalendarDateRange> & { value: { start: DateOnly | undefined, end: DateOnly | undefined } }
export type YesNoAnswer = AnswerBase<InputTypeId.YesNo> & { value: boolean }
export type ComplianceAnswer = AnswerBase<InputTypeId.Compliance> & { value: boolean }
export type DecimalAnswer = AnswerBase<InputTypeId.Decimal> & { value: number }
export type EntityAnswer = AnswerBase<InputTypeId.SingleEntity> & { value: number, displayValue?: string, secondaryKeys?: number[] }
export type MultiEntityAnswer = AnswerBase<InputTypeId.MultiEntity> & { value: { value: number, displayValue?: string, secondaryKeys?: number[] }[] }
export type MonthAnswer = AnswerBase<InputTypeId.Month> & { value: number }
export type YearAnswer = AnswerBase<InputTypeId.Year> & { value: number }
export type IntegerAnswer = AnswerBase<InputTypeId.Integer> & { value: number }

export type RichTextContent =
    {
        contentTypeId: RichTextContentTypeId
        content: RichTextEditorContent
    }

export type RichTextEditorContent = StronglyTypedId<'RichTextEditorContent', unknown>

export enum RichTextContentTypeId {
    Lexical = 1,
}

export type DigitalDocumentTemplateListing =
    {
        id: DigitalDocumentTemplateId
        metadata: DigitalDocumentTemplateMetadata
        previewDocumentId: AnsweredDigitalDocumentId
    }

export type DigitalDocumentTemplate =
    {
        id: DigitalDocumentTemplateId
        metadata: DigitalDocumentTemplateMetadata
        header: TemplateItem[]
        body: TemplateItem[]
        footer: TemplateItem[]
    }

export type TemplateItem =
    | Label
    | PlainTextQuestion
    | RichTextQuestion
    | YesNoQuestion
    | ComplianceQuestion
    | TableQuestion
    | ChecklistQuestion
    | CalendarDateQuestion
    | TupleQuestion

export type ItemContext =
    {
        text: TextContent
        additionalText?: TextContent
        reference?: string
        guidance?: TextContent
        isReadOnly: boolean
        automaticFunctions: {
            functions: AutomaticFunctions
        },
        stylingCustomizations?: {
            backgroundColor?: string
        }
        contributesAnyRestrictions: boolean
        acceptsAnyRestrictions: boolean
    }

export type TextContent =
    | string // Plain text
    | RichTextContent

export enum AutomaticFunctions {
    None = 0,
    Sum = 1 << 0,
}

export type TemplateItemModel<T extends TemplateItemTypeId, TInputTypeId extends InputTypeId = InputTypeId> =
    {
        id: TemplateItemId
        typeId: T
        inputTypeId: TInputTypeId
        order: number
        //location: LayoutLocation
        numbering?: string[]
        sizeHint: LayoutSizeHint
        level: ItemLevel
        lockingDependencies?: TemplateItemId[]
        context: ItemContext
        allowsElaboration: boolean
        allowsNotApplicable: boolean
        secondaryInput?: InputItemModel
    }

export type InputItemModel =
    | LabelInput
    | PlainTextInput
    | RichTextInput
    | YesNoInput
    | ComplianceInput
    | DecimalInput
    | SingleEntityInput
    | MonthInput
    | YearInput
    | TableInput
    | TupleInput
    | VectorInput
    | ChecklistInput
    | CalendarDateInput
    | IntegerInput
    | MultiEntityInput
    | CalendarDateRangeInput

type InputItemModelBase<TInputTypeId extends InputTypeId> = { inputTypeId: TInputTypeId, context: ItemContext }
export type LabelInput = InputItemModelBase<InputTypeId.None>
export type PlainTextInput = InputItemModelBase<InputTypeId.PlainText>
export type IntegerInput = InputItemModelBase<InputTypeId.Integer>
export type RichTextInput = InputItemModelBase<InputTypeId.RichText>
export type CalendarDateInput = InputItemModelBase<InputTypeId.CalendarDate>
export type CalendarDateRangeInput = InputItemModelBase<InputTypeId.CalendarDateRange>
export type YesNoInput = InputItemModelBase<InputTypeId.YesNo>
export type ComplianceInput = InputItemModelBase<InputTypeId.Compliance>
export type DecimalInput = InputItemModelBase<InputTypeId.Decimal>
export type SingleEntityInput = InputItemModelBase<InputTypeId.SingleEntity> & {
    entityCategoryId: EntityCategoryId
    persistDisplayValue: boolean
    isCompositeKeyBasedEntity: boolean
    isUnitOfMeasure: boolean
}
export type MultiEntityInput = InputItemModelBase<InputTypeId.MultiEntity> & {
    entityCategoryId: EntityCategoryId
    persistDisplayValue: boolean
    isCompositeKeyBasedEntity: boolean
    isUnitOfMeasure: boolean
}

export type CompositeKeyAugmentation = {
    secondaryKeys: number[]
}

export enum EntityCategoryId {
    // We do not need to add all cases here as well-known unless we want intrinsic support for them

    CountryOutsideEuId = 33,
}

export enum TableNumbering {
    None = 0,
}

export type HeaderLayout = {
    rows: HeaderRow[]
}

export type HeaderRow = {
    columns: HeaderGrouping[]
}

export type HeaderGrouping =
    | { type: "placeholder" }
    | { type: "columnGroup", groupHeader: string, columnSpan: number }
    | { type: "actualColumn", rowSpan: number }

export type MonthInput = InputItemModelBase<InputTypeId.Month>
export type YearInput = InputItemModelBase<InputTypeId.Year>
export type TableInput = InputItemModelBase<InputTypeId.Table> & { properties: TableProperties }
export type TupleInput = InputItemModelBase<InputTypeId.Tuple> & { properties: TupleProperties }
export type VectorInput = InputItemModelBase<InputTypeId.Vector> & { properties: { definition: InputItemModel, orientation: InputOrientation, unique: boolean } }
export type ChecklistInput = Prettify<InputItemModelBase<InputTypeId.Checklist> & { properties: { items: InputItemModel[] } }>

export type TupleProperties = { items: InputItemModel[], orientation: InputOrientation }
export type TableProperties = { fixedNumberOfRows?: number, columns: InputItemModel[], numbering: TableNumbering, headerLayout?: HeaderLayout, rowHeaders?: (TextContent | null)[] }

export enum InputOrientation {
    Horizontal = 0,
    Vertical = 1,
}

export enum LayoutLocation {
    Header = 1,
    Body = 2,
    Footer = 3,
}

export enum LayoutSizeHint {
    Default = 0,
    Half = 1,
    Third = 2,
}

export enum ItemLevel {
    SectionItem = 1,
    RegularItem = 2,
    NestedItem = 3,
}

export type TableQuestion = TemplateItemModel<TemplateItemTypeId.Table, InputTypeId.Table> & {
    properties: {
        columns: InputItemModel[]
        numbering: TableNumbering
    }
}

export type TupleQuestion = TemplateItemModel<TemplateItemTypeId.Tuple, InputTypeId.Tuple> & {
    properties: TupleProperties
}

export type VectorQuestion = TemplateItemModel<TemplateItemTypeId.Vector, InputTypeId.Vector> & {
    properties: {
        definition: InputItemModel
    }
}

export type ChecklistQuestion = TemplateItemModel<TemplateItemTypeId.Checklist, InputTypeId.Checklist> &
{
    properties: {
        items: InputItemModel[]
    }
}

export type PlainTextQuestion = TemplateItemModel<TemplateItemTypeId.PlainText, InputTypeId.PlainText>
export type RichTextQuestion = TemplateItemModel<TemplateItemTypeId.RichText, InputTypeId.RichText>

export type CalendarDateQuestion = TemplateItemModel<TemplateItemTypeId.CalendarDate, InputTypeId.CalendarDate>

export type YesNoQuestion = TemplateItemModel<TemplateItemTypeId.YesNo, InputTypeId.YesNo>

export type ComplianceQuestion = TemplateItemModel<TemplateItemTypeId.Compliance, InputTypeId.Compliance> & {
    properties: {
        nonComplianceRequiresElaboration: boolean
        complianceRequiresElaboration: boolean
    }
}

export type Label = TemplateItemModel<TemplateItemTypeId.Label, InputTypeId.None>

export type DigitalDocumentTemplateMetadata =
    {
        title: string
        templateDescription: string
        footerText?: string
        subtitleText?: string
        templateOfficialVersioning?: string
        supportsMultipleSheets: boolean
    }

export type InputChoiceContextModel =
    {
        surroundingAnswer: Answer | undefined
        location: InputLocationModel
    }

export type InputLocationModel =
    {
        sheetNumber: number | undefined
        answerKind: AnswerKind
        templateItemId: TemplateItemId
        inputPath: readonly InputPathSegmentModel[]
    }

export type InputPathSegmentModel =
    | OneDimensionModel
    | TwoDimensionsModel

export type OneDimensionModel = {
    segmentType: InputPathSegmentType.OneDimension
    index: number
}

export type TwoDimensionsModel = {
    segmentType: InputPathSegmentType.TwoDimensions
    rowIndex: number
    columnIndex: number
}

export enum InputPathSegmentType {
    OneDimension = 1,
    TwoDimensions = 2,
}

export enum AnswerKind {
    Primary = 1,
    Secondary = 2,
}

export type ChoiceCollectionModel = {
    choices: ChoiceModel[]
    entityCategoryId: EntityCategoryId
}

export type ChoiceModel =
    | IdDisplayPair

export type SummationModel =
    {
        automaticSummation: Answer
    }

export type TableNumberingModel =
    {
        numbering: string[]
    }

export type DigitalDocumentTemplateBuilderContext =
    {
        inspectionId: number
        customerId?: number
        applicationId?: number
        dateOfInspection?: DateOnly
    }

export type AnsweredDigitalDocumentRevisionId = StronglyTypedId<'AnsweredDigitalDocumentRevisionId'>

export type AnsweredDigitalDocumentRevision =
    {
        id: AnsweredDigitalDocumentRevisionId
        revisionNumber: StronglyTypedId<'RevisionNumber'>
        statusId: AnsweredDigitalDocumentRevisionStatusId
        publishedTimestamp?: Date
        draftTimestamp?: Date
        initializedTimestamp: Date
        modifiedUserId: UserId
    }

export type DigitalDocumentValidationResponse =
    {
        id: AnsweredDigitalDocumentId
        validation: DigitalDocumentValidationModel
    }

export type DigitalDocumentValidationModel =
    {
        totalItemCount: number
        invalidItemCount: number
        invalidItems: AnswerInvalidationModel[]
    }

export type AnswerInvalidationModel =
    {
        type: StronglyTypedId<'InvalidAnswerDiagnosticType'>
        message: string
        layoutLocation: LayoutLocation
        location: InputLocationModel
        itemNumbering: string[] | undefined
        isTopLevelRequiredAndMissing: boolean
    }

export class DigitalDocumentsApi {
    constructor(private readonly api: ApiClient) { }

    private static readonly digitalDocumentTemplateBuilderContextKey = 'X-Bio-Dd-Ctx-O'

    public getInfoFromDocumentId = (documentId: number) =>
        getResource<{ id: AnsweredDigitalDocumentId, templateId: DigitalDocumentTemplateId } | null>(this.api, `api/tenants/${this.api.tenantId}/digital-documents/document/${documentId}/info`, null)

    public getDigitalDocumentTemplate = (digitalDocumentTemplateId: number) =>
        getResource<DigitalDocumentTemplate | null>(this.api, `api/tenants/${this.api.tenantId}/digital-documents/templates/${digitalDocumentTemplateId}`, null)

    public getDigitalDocumentTemplates = () =>
        getResource<DigitalDocumentTemplateListing[]>(this.api, `api/tenants/${this.api.tenantId}/digital-documents/templates`, [])

    public upsertDigitalDocument = (id: AnsweredDigitalDocumentId, digitalDocument: AnsweredDigitalDocument, builderContext?: DigitalDocumentTemplateBuilderContext) =>
        putResource<AnsweredDigitalDocument, IdResult<AnsweredDigitalDocumentId>>(this.api, `api/tenants/${this.api.tenantId}/digital-documents/${id}`, digitalDocument,
            {
                headers: DigitalDocumentsApi.maybeAddBuilderContextHeader(builderContext),
            })

    public validateDigitalDocument = (id: AnsweredDigitalDocumentId, digitalDocument?: AnsweredDigitalDocument, builderContext?: DigitalDocumentTemplateBuilderContext) =>
        postResource<AnsweredDigitalDocument | undefined, DigitalDocumentValidationResponse>(this.api, `api/tenants/${this.api.tenantId}/digital-documents/${id}/validation`, digitalDocument,
            {
                onSuccessSilent: true,
                headers: DigitalDocumentsApi.maybeAddBuilderContextHeader(builderContext),
            })

    public toggleDocumentLock = (id: AnsweredDigitalDocumentId, builderContext?: DigitalDocumentTemplateBuilderContext) =>
        postResource<null, IdResult<AnsweredDigitalDocumentId>>(this.api, `api/tenants/${this.api.tenantId}/digital-documents/${id}/locking/toggle`, null,
            {
                headers: DigitalDocumentsApi.maybeAddBuilderContextHeader(builderContext),
            })

    public publishDigitalDocument = (id: AnsweredDigitalDocumentId, digitalDocument: AnsweredDigitalDocument, builderContext?: DigitalDocumentTemplateBuilderContext) =>
        postResource<AnsweredDigitalDocument, IdResult<AnsweredDigitalDocumentId>>(this.api, `api/tenants/${this.api.tenantId}/digital-documents/${id}/publish`, digitalDocument,
            {
                headers: DigitalDocumentsApi.maybeAddBuilderContextHeader(builderContext),
            })

    public getDigitalDocument = (id: AnsweredDigitalDocumentId, builderContext?: DigitalDocumentTemplateBuilderContext) =>
        getResource<AnsweredDigitalDocument | null>(this.api, `api/tenants/${this.api.tenantId}/digital-documents/${id}`, null,
            {
                headers: DigitalDocumentsApi.maybeAddBuilderContextHeader(builderContext),
            })

    public getInputChoices = (id: AnsweredDigitalDocumentId, inputChoiceContext: InputChoiceContextModel, builderContext?: DigitalDocumentTemplateBuilderContext) =>
        postResource<InputChoiceContextModel, ChoiceCollectionModel>(this.api, `api/tenants/${this.api.tenantId}/digital-documents/${id}/inputs/choices`,
            inputChoiceContext,
            {
                onSuccessSilent: true,
                headers: DigitalDocumentsApi.maybeAddBuilderContextHeader(builderContext),
            })

    public getSummation = (data: Answer[], location: InputLocationModel, builderContext?: DigitalDocumentTemplateBuilderContext) =>
        postResource<unknown, SummationModel>(this.api, `api/tenants/${this.api.tenantId}/digital-documents/auxiliary/summation`,
            {
                data,
                location,
            },
            {
                onSuccessSilent: true,
                headers: DigitalDocumentsApi.maybeAddBuilderContextHeader(builderContext),
            })

    public getTableInputNumbering = (numbering: TableNumbering, rows: number, builderContext?: DigitalDocumentTemplateBuilderContext) =>
        getResource<TableNumberingModel | null>(this.api, `api/tenants/${this.api.tenantId}/digital-documents/auxiliary/table-numbering`, null,
            {
                queryParams: {
                    numbering,
                    rows
                },
                onSuccessSilent: true,
                headers: DigitalDocumentsApi.maybeAddBuilderContextHeader(builderContext),
            })

    public getDocumentRevisions = (id: AnsweredDigitalDocumentId, builderContext?: DigitalDocumentTemplateBuilderContext) =>
        getResource<{ revisions: AnsweredDigitalDocumentRevision[] }>(this.api, `api/tenants/${this.api.tenantId}/digital-documents/${id}/revisions`, { revisions: [] },
            {
                headers: DigitalDocumentsApi.maybeAddBuilderContextHeader(builderContext),
            })

    public getDocumentPdf = (id: AnsweredDigitalDocumentId, revisionId: undefined | AnsweredDigitalDocumentRevisionId, builderContext?: DigitalDocumentTemplateBuilderContext) =>
        getResource<BlobResponse | null>(this.api, `api/tenants/${this.api.tenantId}/digital-documents/${id}/generate-pdf`, null,
            {
                blob: true,
                headers: DigitalDocumentsApi.maybeAddBuilderContextHeader(builderContext),
                queryParams: { revisionId },
                timeout: this.api.maxTimeoutMs,
            })

    public getUnitsOfMeasure = () =>
        getResource<{ unitsOfMeasure: IdDisplayPair[] }>(this.api, `api/tenants/${this.api.tenantId}/digital-documents/auxiliary/units-of-measure`, { unitsOfMeasure: [] })

    public getRevisionStatuses = () =>
        getResource<{ statuses: IdDisplayPair[] }>(this.api, `api/tenants/${this.api.tenantId}/digital-documents/revisions/statuses`, { statuses: [] })

    private static maybeAddBuilderContextHeader(builderContext?: DigitalDocumentTemplateBuilderContext) {
        if (!builderContext) return undefined
        return { [DigitalDocumentsApi.digitalDocumentTemplateBuilderContextKey]: JSON.stringify(builderContext) }
    }
}