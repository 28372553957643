import { idOrEmptyRoute } from "../utils/utils"
import { ApiClient, BlobResponse } from "./ApiClient"
import { IdDisplayPair } from "./Common"
import { ApiDescription, EMPTY_API_DESCRIPTION, FilterWithValue, Paginated, PaginationRequest, SortCriterionWithValue, combineQueryParams, fakePaginated, getResource, mapFiltersToQueryParamsObject, mapSortingToQueryParamObject } from "./Search"
import { ExportExcelTimeout, TicketPriority, TicketStatusId, TicketTypeId } from "./WellKnowIds"

export type TicketListingViewModel =
    {
        ticketId: number
        assignedUser?: string
        customerId: number
        customerDistinctiveTitle: string
        customerFullName?: string
        priority: TicketPriority
        dateCreated: Date
        dateModified: Date
        ticketStatus: string
        ticketStatusId: TicketStatusId
        inspectionIds: number[]
        certificationStandard: string
        ticketType: string
        ticketTypeDocument?: string
        ticketTypeId: TicketTypeId
        note?: string
        reasonBlockedDescription?: string
        assignmentDate?: Date
        inspectionHasNonCompliance?: boolean
        inspectionIsSelling?: boolean
        inspectionHasDocumentRequest?: boolean
        inspectionDate?: Date
        currentCustomerAgreementStartDate?: Date
        inspectionBasicGroupAbbreviations?: string[]
    }

export type TicketBindingModel =
    {
        customerId?: number
        ticketTypeId: TicketTypeId
        ticketType: string
        ticketTypeDocumentId?: number
        certificationStandardId: number
        priority: TicketPriority
        note?: string
        useCurrentCustomerView?: boolean
        inspectionId?: number
        customerViewDate?: Date
        additionalTicketIds?: number[]
    }

export type TicketViewModel = TicketBindingModel &
{
    ticketId?: number,
    ticketType: string
    ticketTypeDocument?: string,
    certificationStandard: string
    ticketStatusId: TicketStatusId,
    ticketStatus: string,
    complianceDecisionId?: number
    assignedUserId?: number
    selfAssign?: boolean
}

export const EMPTY_TICKET: TicketViewModel =
{
    ticketTypeId: TicketTypeId.IssueDocument,
    priority: TicketPriority.P2,
    certificationStandardId: 0,
    ticketType: "",
    certificationStandard: "",
    ticketStatusId: TicketStatusId.Backlog,
    ticketStatus: '',
}

export type TicketStatusHistoryRecord =
    {
        userFullName: string
        ticketStatus?: string
        assignedUserFullName?: string
        isUnassignment?: boolean
        blockedInspectionId?: number
        priority?: TicketPriority
        dateCreated: Date
        reasonBlockedText?: string
        reasonBlockedId?: string
        reasonBlockedDescription?: string
    }

export type ReasonBlocked =
    {
        reasonBlockedId: number
        description: string
    }

export type ReasonBlockedModel =
    {
        inspectionId?: number
        reasonBlockedId: number
        reasonBlockedText: string
        notify?: boolean
    }

export type InspectionAssessmentPair =
    {
        inspectionId: number,
        inspectionDate: Date,
        complianceDecisionId: number,
        complianceDecisionDate: Date,
        isPending: boolean
    }

export type TicketTypeDocumentViewModel =
    {
        ticketTypeDocumentId: number
        type: string
        notesRequired?: boolean
    }

export type CandidateInspectionResponse =
    {
        defaultInspectionId?: number
        inspections: CandidateInspection[]
    }

export type CandidateInspection =
    {
        inspectionId: number
        inspector?: string
        inspectionStatus: string
        inspectionType: string
        date: Date
    }

export type PendingTicketViewModel =
    {
        ticketId: number
        assignedUser?: string
        priority: TicketPriority
        dateCreated: Date
        ticketStatusId: TicketStatusId
        ticketStatus: string
        ticketTypeId: TicketTypeId
        ticketType: string
        ticketTypeDocument?: string
        isAssignable: boolean
    }

export class TicketsApi {
    constructor(private readonly api: ApiClient) { }

    public getPending = (customerId: number) =>
        getResource<PendingTicketViewModel[]>(this.api, `api/tenants/${this.api.tenantId}/tickets/pending`, [], { queryParams: { customerId } })

    public get = (pagination: PaginationRequest, filters: FilterWithValue[], sorting: SortCriterionWithValue[]) => {
        const queryParams = combineQueryParams(pagination, mapFiltersToQueryParamsObject(filters), mapSortingToQueryParamObject(sorting))
        return this.getFromHref(`api/tenants/${this.api.tenantId}/tickets?${queryParams.toString()}`)
    }

    public getFromHref = (href: string) =>
        this.api.onUnexpected(
            this.api.execute<Paginated<TicketListingViewModel>>(200, 'GET', href),
            "Could not load tickets", fakePaginated([] as TicketListingViewModel[]))

    public describe = () =>
        this.api.onUnexpected(
            this.api.execute<ApiDescription>(200, 'OPTIONS', `api/tenants/${this.api.tenantId}/tickets`),
            "Could not load api description", EMPTY_API_DESCRIPTION)

    public batchAssign = (ticketIds: number[] | null, filters: FilterWithValue[] | null, ticketStatusId?: number, userId?: number, priority?: TicketPriority, isUnassigned?: boolean, reasonBlockedModel?: ReasonBlockedModel) =>
        this.api.onUnexpected(
            this.api.execute<{ recordsChanged: number }>(200, 'POST', `api/tenants/${this.api.tenantId}/tickets/assign`, mapFiltersToQueryParamsObject(filters ?? []), { ticketIds: ticketIds, ticketStatusId: ticketStatusId, userId: userId, priority: priority, isUnassigned: isUnassigned, reasonBlockedModel: reasonBlockedModel }),
            "Could not assign tickets", null)

    public getTicketStatuses = () =>
        this.api.onUnexpected(
            this.api.execute<IdDisplayPair[]>(200, 'GET', `api/tenants/${this.api.tenantId}/tickets/statuses`),
            "Could not load ticket statuses", [] as IdDisplayPair[])

    public getTicketTypes = () =>
        this.api.onUnexpected(
            this.api.execute<IdDisplayPair[]>(200, 'GET', `api/tenants/${this.api.tenantId}/tickets/types`),
            "Could not load ticket types", [] as IdDisplayPair[])

    public getTicketTypesDocument = () =>
        this.api.onUnexpected(
            this.api.execute<TicketTypeDocumentViewModel[]>(200, 'GET', `api/tenants/${this.api.tenantId}/tickets/typesdocument`),
            "Could not load ticket types document", [] as TicketTypeDocumentViewModel[])

    public pickTicket = () =>
        this.api.onUnexpected(
            this.api.execute<{}>(200, 'POST', `api/tenants/${this.api.tenantId}/tickets/pick`),
            "Δεν βρέθηκαν εκκρεμείς εργασίες", null)

    public upsertTicket = (ticketId: number | undefined, model: TicketBindingModel) =>
        this.api.onUnexpected(
            this.api.execute<{ ticketId: number }>(200, 'PUT', `api/tenants/${this.api.tenantId}/tickets${idOrEmptyRoute(ticketId)}`, undefined, model),
            "Could not upsert ticket", null)

    public deleteTicket = (ticketId: number) =>
        this.api.onUnexpected(
            this.api.execute<void>(204, 'DELETE', `api/tenants/${this.api.tenantId}/tickets/${ticketId}`),
            "Could not delete ticket", undefined)

    public getTicket = (ticketId: number) =>
        this.api.onUnexpected(
            this.api.execute<TicketViewModel>(200, 'GET', `api/tenants/${this.api.tenantId}/tickets${idOrEmptyRoute(ticketId)}`),
            "Could not get ticket", EMPTY_TICKET)

    public getTicketCompletionCheck = (ticketId: number) =>
        this.api.onUnexpected(
            this.api.execute<{ warnings: string[] }>(200, 'GET', `api/tenants/${this.api.tenantId}/tickets/${ticketId}/completion-check`),
            "Could not get ticket completion check", null)

    public getTicketStatusHistoryRecords = (ticketId: number) =>
        this.api.onUnexpected(
            this.api.execute<TicketStatusHistoryRecord[]>(200, 'GET', `api/tenants/${this.api.tenantId}/tickets${idOrEmptyRoute(ticketId)}/records`),
            "Δεν φορτώθηκε το ιστορικό των αλλαγών κατάστασης εργασίας", [] as TicketStatusHistoryRecord[])

    public getExcel = (filters: FilterWithValue[], sorting: SortCriterionWithValue[]) => {
        const queryParams = combineQueryParams(mapFiltersToQueryParamsObject(filters), mapSortingToQueryParamObject(sorting))
        return this.api.onUnexpected(
            this.api.execute<BlobResponse>(200, 'GET', `api/tenants/${this.api.tenantId}/tickets/export`, queryParams, undefined, ExportExcelTimeout, undefined, undefined, true),
            "Could not export given search to Excel",
            null)
    }

    public getReasonsBlocked = () =>
        this.api.onUnexpected(
            this.api.execute<ReasonBlocked[]>(200, 'GET', `api/tenants/${this.api.tenantId}/tickets/reasons-blocked`),
            "Could not get reasons blocked", [] as ReasonBlocked[])

    public getIssueDocumentCorrelatedAssessment = (complianceDecisionId: number) =>
        this.api.onUnexpected(
            this.api.execute<{ ticketId: number }>(200, 'GET', `api/tenants/${this.api.tenantId}/tickets/correlated-assessment/${complianceDecisionId}`), "Δεν υπάρχει διαθέσιμη αξιολόγηση", null)

    public getCustomerAssessmentInspectionPairs = (customerId: number) =>
        this.api.onUnexpected(
            this.api.execute<InspectionAssessmentPair[]>(200, 'GET', `api/tenants/${this.api.tenantId}/tickets/assessments-inspections/${customerId}`), "Δεν υπάρχει διαθέσιμη αξιολόγηση", [] as InspectionAssessmentPair[])

    public getBlockedTicketsReport = (startDate?: Date, endDate?: Date) =>
        getResource<BlobResponse, null>(this.api, `api/tenants/${this.api.tenantId}/tickets/reports/blocked-tickets`, null, { timeout: 0, blob: true, queryParams: { startDate, endDate } })

    public getBlockedTicketsAnalysisReport = () =>
        getResource<BlobResponse, null>(this.api, `api/tenants/${this.api.tenantId}/tickets/reports/blocked-tickets-analysis`, null, { timeout: 0, blob: true })

    public getCandidateInspections = (ticketId: number) =>
        getResource<CandidateInspectionResponse>(this.api, `api/tenants/${this.api.tenantId}/tickets/${ticketId}/candidate-inspections`, { defaultInspectionId: undefined, inspections: [] })
}